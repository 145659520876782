



const initial_state = {
    overview : {
        total_premium: 0,
        telenor_revenue: 0,
        users: 0,
        orders: 0
    },
    timerange_value : {
        value: 'today',
        text : 'Today'
    },
    dates : {
        start : new Date(),
        end : new Date(),
    }
}


export default function alternativeDashboardReducer(state = initial_state , action){

    switch(action.type){
        case 'OVERVIEW':{
            return {
                ...state,
                overview : action.payload
            }
        }

        case 'TIMERANGE': {
            return {
                ...state,
                timerange_value : action.payload
            }
        }

        case 'DATE_RANGE':{
            return {
                ...state,
                dates : action.payload
            }
        }
        
        default:
            return state       
    }
}