

const initial_state = {
    progress_over_time : [],

    live_activity : { 
        USERS : 0,
        PAGES : [] // pages_sample = [ ['/' , 1] , ['/health-insurance' , 4]  ...]  
    },
    
    unprocessed_orders : 0,

    processed_orders : 0,

    sales_today : 0,

    total_orders : 0,

    category : {
        name : 'All',
        value : '7,6,4,3,2',
    },
    dates : {
        start : new Date(),
        end : new Date()
    }
}


export default function dashboardReducer(state = initial_state , action){

    switch(action.type){
        // update : make a api call to backend to get new values
        case 'PROGRESS_OVER_TIME':
            //console.log('HURRAY IN THE REDUCER WITH ACTION UPDATE')
            return {
                    ...state,
                    progress_over_time: action.payload
                    }

        case 'ACTIVE_USERS':
            //console.log('inside active users!!');
            return {
                ...state,
                live_activity : action.payload

            }

        case 'UNPROCESSED_ORDERS':
            return {
                ...state,
                unprocessed_orders : action.payload.unprocessed_orders
            }

        case 'PROCESSED_ORDERS':
            return {
                ...state,
                processed_orders : action.payload.processed_orders
            }

        case 'SALES_TODAY':
            return {
                ...state,
                sales_today : action.payload.sales_today
            }
        
        case 'TOTAL_ORDERS':
            return{
                ...state,
                total_orders : action.payload.total_orders
            }
        case 'CATEGORY':
            return {
                ...state,
                category : action.payload,
            }
        case 'DATE_RANGE':
            return {
                ...state,
                dates : action.payload,
            }
        default:
            return state;
        }
    }
